// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
    @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
    @each $color, $value in $theme-colors {
        @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
    }
}

.bg-white {
    background-color: $white !important;
}

.bg-transparent {
    background-color: transparent !important;
}
