.layout {

    #wrapper {

        > .content-wrapper {

            > .content {
                position: relative;
            }
        }
    }
}