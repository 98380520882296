#maintenance {
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 100%;
    background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-wrapper {
        width: 38.4rem;
        max-width: 38.4rem;
        background: #FFFFFF;
        text-align: center;

        .logo {
            width: 12.8rem;
            height: 12.8rem;
            line-height: 12.8rem;
            font-size: 8.6rem;
            font-weight: 500;
            margin: 3.2rem auto;
            color: #FFFFFF;
            border-radius: 2px;
        }

        .title {
            font-size: 1.7rem;
        }
        .subtitle {
            text-align: center;
            max-width: 30rem;
            font-size: 1.5rem;
        }
    }
}

// RESPONSIVE
@include media-breakpoint-down(sm) {

    #maintenance {

        .form-wrapper {
            padding: 1.6rem;
            padding: 2.4rem;
            width: 100%;

            form {
            }
        }
    }
}