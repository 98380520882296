// List Group
.list-group {
    padding: 0.8rem 0;

    &.dense {
        padding: 0.4rem 0;

        .list-group-item {
            padding: 0.6rem 1.6rem;
            min-height: 4rem;
            line-height: 1.6rem;

            > .avatar {
                width: 36px;
                min-width: 36px;
                height: 36px;
                margin: 0 2rem 0 0;

                > img {
                    max-width: 36px;
                    max-height: 36px;
                }
            }

            &.two-line {
                min-height: 6rem;
            }

            &.three-line {
                min-height: 7.6rem;
            }
        }
    }

    .list-group-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: none;
        padding: 0.8rem 1.6rem;
        min-height: 4.8rem;
        line-height: 1.6rem;
        text-decoration: none;

        > .icon {
            margin-right: 3.2rem;
        }

        > .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            width: 40px;
            height: 40px;
            margin: 0 1.6rem 0 0;
            border-radius: 100%;

            > img {
                max-width: 40px;
                max-height: 40px;
            }
        }

        > .list-item-content {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        > .secondary-container {
            display: flex;
            margin-left: 1.6rem;
        }

        h3 {
            font-size: 1.4rem;
            margin: 0;
        }

        h4 {
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: 0.01em;
            margin: 1px 0 3px 0;
        }

        p {
            font-size: 1.3rem;
            font-weight: 500;
            letter-spacing: 0.01em;
            margin: 0;
            //color: rgba(0, 0, 0, 0.54);
        }

        &.subheader {
            min-height: 4.8rem;
            height: 4.8rem;
            font-weight: 500;
            font-size: 13px;
            //color: rgba(0, 0, 0, 0.54);

            &.align-with-text {
                padding-left: 72px;
            }
        }

        &.two-line {
            min-height: 7.2rem;
        }

        &.three-line {
            min-height: 8.8rem;
            align-items: flex-start;

            > .icon,
            > .avatar,
            > .secondary-container {
                margin-top: 0.4rem;
            }
        }

        &.two-line {
            min-height: 7.2rem;
        }

        &.three-line {
            min-height: 8.8rem;
            align-items: flex-start;

            > .icon,
            > .avatar,
            > .secondary-container {
                margin-top: 0.4rem;
            }
        }

        &:first-child {

        }

        &:last-child {

        }

        &.disabled,
        &:disabled {

        }

        // Include both here for `<a>`s and `<button>`s
        &.active {

        }
    }
}

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive
// list items. Includes an extra `.active` modifier class for selected items.

.list-group-item-action {

    // Hover state
    @include hover-focus {

    }

    &:active {

    }
}

// Flush list items
//
// Remove borders and border-radius to keep list group items edge-to-edge. Most
// useful within other components (e.g., cards).

.list-group-flush {

    .list-group-item {

    }

    &:first-child {

        .list-group-item:first-child {

        }
    }

    &:last-child {

        .list-group-item:last-child {

        }
    }
}
