#calendar {

    .page-header {
        position: relative;
        background-size: 100% auto;
        background-position: 0 50%;
        background-repeat: no-repeat;
        background-color: #FAFAFA;
        color: #FFFFFF;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.45);
        }

        &.Jan {
            background-image: url('/assets/images/backgrounds/winter.jpg');
            background-position: 0 85%;
        }

        &.Feb {
            background-image: url('/assets/images/backgrounds/winter.jpg');
            background-position: 0 85%;
        }

        &.Mar {
            background-image: url('/assets/images/backgrounds/spring.jpg');
            background-position: 0 40%;
        }

        &.Apr {
            background-image: url('/assets/images/backgrounds/spring.jpg');
            background-position: 0 40%;
        }

        &.May {
            background-image: url('/assets/images/backgrounds/spring.jpg');
            background-position: 0 40%;
        }

        &.Jun {
            background-image: url('/assets/images/backgrounds/summer.jpg');
            background-position: 0 80%;
        }

        &.Jul {
            background-image: url('/assets/images/backgrounds/summer.jpg');
            background-position: 0 80%;
        }

        &.Aug {
            background-image: url('/assets/images/backgrounds/summer.jpg');
            background-position: 0 80%;
        }

        &.Sep {
            background-image: url('/assets/images/backgrounds/autumn.jpg');
            background-position: 0 40%;
        }

        &.Oct {
            background-image: url('/assets/images/backgrounds/autumn.jpg');
            background-position: 0 40%;
        }

        &.Nov {
            background-image: url('/assets/images/backgrounds/autumn.jpg');
            background-position: 0 40%;
        }

        &.Dec {
            background-image: url('/assets/images/backgrounds/winter.jpg');
            background-position: 0 85%;
        }

        #add-event-button {
            position: absolute;
            right: 2.4rem;
            bottom: -2.8rem;
            z-index: 99;
        }

        .header-content {
            height: 100%;

            .header-top {
                z-index: 2;
            }

            .header-bottom {
                z-index: 2;
            }
        }
    }

    .page-content {

        #calendar-view {

            &.fc {

                .fc-widget-header {
                    border: none;
                    color: rgba(0, 0, 0, 0.54);

                    .fc-day-header {
                        text-align: left;
                        border: none;
                        font-weight: 500;
                        padding: .8rem;
                    }
                }

                .fc-widget-content {
                    color: rgba(0, 0, 0, 0.54);

                    .fc-day-grid-container {

                        .fc-day-grid {

                            .fc-day-number {
                                text-align: left;
                                padding: .8rem 8px 0 .8rem;
                            }
                        }
                    }

                    .fc-time-grid-container {
                        overflow: hidden;
                        height: auto !important;

                        .fc-axis {
                            font-weight: 500;
                            border: none;
                        }
                    }
                }

                .fc-day-grid-event {
                    margin: .4rem .8rem 0 .8rem;
                    padding: .2rem .4rem;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                }

                .fc-time-grid-event {
                    color: #FFFFFF;
                }

                .fc-month-view {
                }

                .fc-agenda-view {

                    .fc-widget-header {

                        .fc-day-header {
                            border: 1px solid #DDD;
                            line-height: 5rem;
                            font-size: 1.7rem;
                        }
                    }

                    > table > tbody > tr > td.fc-widget-content {
                        border: none;
                    }

                    .fc-minor .fc-widget-content {
                        border-top: none;
                    }

                    .fc-week, .fc-day {
                        height: 10rem !important;
                    }

                    .fc-widget-content {
                        height: 5rem;
                    }

                    .fc-axis {
                        padding-left: 2.4rem;
                    }
                }
            }
        }
    }
}