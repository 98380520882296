.nvd3-doc {

    #line-chart {
        height: 45rem;
    }

    #stacked-area-chart {
        height: 45rem;
    }

    #sparkline-chart {
        height: 45rem;
    }

    #historical-bar-chart {
        height: 45rem;
    }

    #multi-bar-horizontal-chart {
        height: 45rem;
    }

    #pie-chart {
        height: 50rem;
    }

    #donut-chart {
        height: 25rem;
    }

    #bullet-chart {
    }

    #scatter-chart {
        height: 45rem;
    }
}