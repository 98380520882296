#chat {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .top-bg {
    }

    .page-content {
        display: flex;
        flex: 1;
        max-width: 1400px;

        > .page-content-card {
            background: url('/assets/images/patterns/rain-grey.png');

            > .left-sidebar {
                position: relative;
                overflow: hidden;
                width: 40rem;
                background: #FFF;
                border-right: 1px solid rgba(0, 0, 0, .12);

                #chat-left-sidebar-views {
                    height: 100%;

                    .view {
                        height: 100%;

                        > .content {
                            overflow-x: hidden;
                            overflow-y: auto;
                        }
                    }

                    #chats-view {

                        .toolbar {
                            background-color: #F3F4F5;
                            color: rgba(0, 0, 0, 0.87);

                            .toolbar-top {
                                border-bottom: 1px solid rgba(0, 0, 0, .08);
                                height: 6.4rem;

                                .avatar-wrapper {
                                    cursor: pointer;

                                    .status {
                                        position: absolute;
                                        top: 2.8rem;
                                        left: 2.8rem;
                                        border-radius: 50%;
                                    }
                                }
                            }

                            .toolbar-bottom {
                                border-bottom: 1px solid rgba(0, 0, 0, .08);
                                background-color: #FAFAFA;
                                height: 6.4rem;

                                .search-wrapper {
                                    height: 3.6rem;
                                    background-color: #FFFFFF;

                                }
                            }
                        }

                        > .content {

                            .chat-list {

                                .contact {
                                    min-height: 8.8rem;

                                    .name {

                                    }

                                    .last-message {
                                        max-width: 180px;
                                        margin-bottom: 0;
                                    }

                                    .last-message-time {
                                        white-space: nowrap;
                                        font-size: 1.3rem;
                                    }

                                    &.unread {

                                        .name, .last-message, .last-message-time {
                                            font-weight: 500;
                                        }
                                    }

                                    .unread-message-count {
                                        border-radius: 50%;
                                        text-align: center;
                                        width: 24px;
                                        height: 24px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }

                        #contacts-button {

                        }
                    }

                    #contacts-view {

                        > .toolbar {

                            .toolbar-top {
                                height: 6.4rem;
                            }

                            .toolbar-bottom {
                                height: 6.4rem;

                                .search-wrapper {
                                    height: 3.6rem;
                                    background-color: #FFFFFF;
                                }
                            }
                        }

                        > .content {

                            .contact-list {

                                .contact {
                                    position: relative;
                                    height: 8.8rem;
                                    min-height: 8.8rem;

                                    .name {
                                    }

                                    .mood {
                                        font-size: 1.3rem;
                                    }
                                }
                            }
                        }
                    }

                    #user-view {

                        > .toolbar {
                            height: 30rem;

                            .toolbar-top {
                                height: 6.4rem;
                            }

                            .toolbar-bottom {

                                .search-wrapper {
                                    height: 3.6rem;
                                    background-color: #FFFFFF;
                                }
                            }
                        }

                        > .content {
                        }
                    }
                }
            }

            > .content {
                display: flex;

                #chat-content-views {
                    display: flex;
                    flex: 1;

                    > .view {
                        flex: 1;
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.6) 20%, rgba(255, 255, 255, 0.8));
                    }

                    #start-view {

                        .big-circle {
                            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.6) 20%, rgba(255, 255, 255, 0.8));
                            border-radius: 50%;
                            width: 300px;
                            height: 300px;
                            line-height: 300px;
                            text-align: center;
                        }

                        .app-title {
                            font-weight: 500;
                        }

                        .secondary-text {
                        }
                    }

                    #chat-view {

                        .toolbar {
                            flex: 1 0 auto;
                            background-color: #F3F4F5;
                            border-bottom: 1px solid rgba(0, 0, 0, .08);
                            height: 6.4rem;
                            max-height: 6.4rem;
                        }

                        .chat-content {
                            flex: 1 1 100%;

                            @include firefox-only() {
                                flex: 1 0 0px;
                            }

                            overflow-x: hidden;
                            overflow-y: auto;

                            .chat-messages {

                                .message-row {

                                    .bubble {
                                        position: relative;
                                        padding: .6rem .7rem .8rem .9rem;
                                        background-color: #FFF;
                                        box-shadow: 0 1px .5px rgba(0, 0, 0, .13);
                                        border-radius: 6px;

                                        &:before {
                                            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADGUExURQAAAP////b29vn5+f///wAAAP///wAAAAAAAP///9ra2v////j4+PHx8fv7++Hh4fHx8f////////////////39/QAAAP////////z8/P////39/f39/fz8/P////////////z8/P////////////z8/P////////////v7+/Hx8f///9bW1vz8/K2trf////39/f39/WJiYgAAAExMTFtbWwAAAN3d3cjIyPr6+vX19QAAAO7u7vz8/NTU1Ofn5zMzM////zGPlXsAAABBdFJOUwAcm/kREh4CCDWL1SneR6TfAQffhMYK/A5nRrLWfRc5DW2ih5f+19Kn+9v4g/1LCJuXHwQUKgahcXS6DNnlDMMKKzPoTgAAAKBJREFUKM+V08USwmAQA+C/0NIWd3d3d8/7vxTMcIPkQK7f7CG7s8bQAOY/SCuwFYQU1P+eiCqIK2gpWCmoCrAgoKQgJ8CHgIqAMjg0MxxSQ3DogEMWFBZtUPAHYGB1CyDQWE6AH7BrfXzlAxGAQhECTGAmwN1Okz0Gb/LW4fEItIfrOfNELMh3tck7u+PhcT2zQ7l77/K8iY8yJwV3BeYFqpc/uSyPGdAAAAAASUVORK5CYII=);
                                            content: '';
                                            position: absolute;
                                            left: -1.1rem;
                                            bottom: .3rem;
                                            width: 1.2rem;
                                            height: 1.9rem;
                                            background-position: 50% 50%;
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                        }

                                        .message {
                                            white-space: pre-wrap;
                                        }

                                        .time {
                                            font-size: 1.1rem;
                                        }
                                    }

                                    &.contact {

                                        .avatar {
                                        }
                                    }

                                    &.user {
                                        align-items: flex-end;

                                        .avatar {
                                            order: 2;
                                            margin: 0 0 0 16px;
                                        }

                                        .bubble {
                                            margin-left: auto;
                                            background-color: #E8F5E9;
                                            border: 1px solid #DFEBE0;
                                            order: 1;

                                            &:before {
                                                right: -11px;
                                                left: auto;
                                                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAD2UExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGRsXAAAANzwzNPmxNrtyau5oIWRedDkwNntyczgwdfpyJ+/n97wzsLWtNjsytvwzczfvtPmxau6nNjqxtrtyio1KtzwzNjryAAAANzwzgAAANzwzK7Aor/Us9Lnw8vevAAAAMzevtbpxrvMrX+IdwAAAEROOi45Lr3MrZGjf9LoxX+MctnqydLkwhgYGMzfv9vuyQAAANzwzNvuy9zxy7vMu7XGqNvtzKKykwAAANruzKq6nLnMriQkGMXXuL3PsNjsySgzKAAAANLkw83fvd3vy9z4xtzwzRpFmIEAAABQdFJOUwAXChEGBAMBAgwhDvJ7k0YqMc0Zmwj6apf2kjU0+dkw/swh/CP9j2Wr2gndvaYeBRoxQg6gUPt/FaHJGdTj9A9k7XQLeE6iFcN12xkSt9r4NKizowAAAMFJREFUKM+V0sdywlAMBVDbMX7PQCihQ+iQ0HsJvfem/P/PwBIzugu0PXNnNNJVyPmhsIPhhoB2COwIGuLdhAcl3AhCBoBoHUC6BCBbA0C/EkBFB5D/FjxQwQYg1RI8UKINgDoSAPUlAPqUAMgfAEBfXsEDBV0+Hogi4Zhg4THj9YwHoqEBYOrgYTI3GVgMNn8r+Qq94k9yZNosW/3Hy9VuTjWfHkOX6367bGZUU7de66ieHZrO1OGg8Z1WTgYAFLgD5S1PCkzo1B0AAAAASUVORK5CYII=);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .chat-footer {
                            flex: 1 0 auto;
                            min-height: 6.4rem;
                            max-height: 9.6rem;
                            background-color: #F3F4F5;
                            border-top: 1px solid rgba(0, 0, 0, .08);
                        }
                    }

                }

            }

            .avatar-wrapper {
                position: relative;

                .status {
                    position: absolute;
                    top: 28px;
                    left: 28px;
                    border-radius: 50%;

                    &.online {
                        color: #4CAF50;

                        &:before {
                            content: "\ea7a";
                        }
                    }

                    &.do-not-disturb {
                        color: #F44336;

                        &:before {
                            content: "\eb59";
                        }
                    }

                    &.away {
                        background-color: #FFC107;
                        color: #FFFFFF;

                        &:before {
                            content: "\eaae";
                        }
                    }

                    &.offline {
                        color: #4CAF50;
                        background-color: #FFFFFF;

                        &:before {
                            content: "\ea7c";
                        }
                    }
                }
            }
        }
    }
}