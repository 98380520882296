.ui-pnotify {

    // Material design styling for PNotify
    &.md {
        width: auto !important;

        .ui-pnotify-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            background: #323232;
            color: #FFFFFF;
            min-height: 4.8rem !important;
            height: 4.8rem !important;
            border-radius: 2px;
            padding: 1.4rem 2.4rem;

            .ui-pnotify-title,
            .ui-pnotify-icon,
            .ui-pnotify-closer,
            .ui-pnotify-sticker {
                display: none !important;
                opacity: 0 !important;
                visibility: hidden !important;
                width: 0 !important;
                height: 0 !important;
                padding: 0 !important;
                margin: 0 !important;
            }

            .ui-pnotify-text {
                white-space: nowrap;
                margin-right: 2.4rem;
                line-height: 2rem;
            }

            .ui-pnotify-action-bar {
                margin: 0 0 0 auto !important;
                text-align: left !important;

                .btn.btn-link {
                    padding: 0;
                    color: theme-color("danger");
                }
            }
        }

        &.multiline {

            .ui-pnotify-container {
                height: auto !important;
                min-height: 8rem !important;
                padding: 2.4rem;

                .ui-pnotify-text {
                    max-width: 240px;
                    width: 240px;
                    line-height: 1.5;
                    white-space: normal;
                }
            }

            &.action-on-bottom {

                .ui-pnotify-container {
                    max-width: 376px;
                    min-height: 12.2rem !important;
                    padding: 2.4rem 2.4rem 1.4rem 2.4rem;

                    .ui-pnotify-text {
                        max-width: none;
                        width: 100%;
                        margin: 0 0 1.4rem 0;
                    }
                }
            }
        }

    }
}