#lock-screen {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-wrapper {
        width: 38.4rem;
        max-width: 38.4rem;
        background: #FFFFFF;

        .avatar-container {
            position: relative;

            i {
                position: absolute;
                bottom: -4px;
                right: -6px;
            }
        }

        .title {
            font-size: 1.7rem;
        }

        .subtitle {
            font-size: 1.3rem;
            color: rgba(0, 0, 0, 0.54);
        }

        form {
            width: 100%;
            text-align: left;

            .submit-button {
                width: 22rem;
            }
        }

        .message {
            font-weight: 500;
        }
    }
}

// RESPONSIVE
@include media-breakpoint-down(sm) {

    #lock-screen {

        .form-wrapper {
            padding: 1.6rem;
            padding: 2.4rem;
            width: 100%;

            form {

                .btn {
                    width: 95%;
                }
            }
        }
    }
}