.source {
    .hll {
        background-color: #FFC;
    }
    .c {
        color: #999;
    }
    .k {
        color: #069;
    }
    .o {
        color: #555;
    }
    .cm {
        color: #999;
    }
    .cp {
        color: #099;
    }
    .c1 {
        color: #999;
    }
    .cs {
        color: #999;
    }
    .gd {
        background-color: #FCC;
        border: 1px solid #C00;
    }
    .ge {
        font-style: italic;
    }
    .gr {
        color: #F00;
    }
    .gh {
        color: #030;
    }
    .gi {
        background-color: #CFC;
        border: 1px solid #0C0;
    }
    .go {
        color: #AAA;
    }
    .gp {
        color: #009;
    }
    .gu {
        color: #030;
    }
    .gt {
        color: #9C6;
    }
    .kc {
        color: #069;
    }
    .kd {
        color: #069;
    }
    .kn {
        color: #069;
    }
    .kp {
        color: #069;
    }
    .kr {
        color: #069;
    }
    .kt {
        color: #078;
    }
    .m {
        color: #F60;
    }
    .s {
        color: #D44950;
    }
    .na {
        color: #4F9FCF;
    }
    .nb {
        color: #366;
    }
    .nc {
        color: #0A8;
    }
    .no {
        color: #360;
    }
    .nd {
        color: #99F;
    }
    .ni {
        color: #999;
    }
    .ne {
        color: #C00;
    }
    .nf {
        color: #C0F;
    }
    .nl {
        color: #99F;
    }
    .nn {
        color: #0CF;
    }
    .nt {
        color: #2F6F9F;
    }
    .nv {
        color: #033;
    }
    .ow {
        color: #000;
    }
    .w {
        color: #BBB;
    }
    .mf {
        color: #F60;
    }
    .mh {
        color: #F60;
    }
    .mi {
        color: #F60;
    }
    .mo {
        color: #F60;
    }
    .sb {
        color: #C30;
    }
    .sc {
        color: #C30;
    }
    .sd {
        font-style: italic;
        color: #C30;
    }
    .s2 {
        color: #C30;
    }
    .se {
        color: #C30;
    }
    .sh {
        color: #C30;
    }
    .si {
        color: #A00;
    }
    .sx {
        color: #C30;
    }
    .sr {
        color: #3AA;
    }
    .s1 {
        color: #C30;
    }
    .ss {
        color: #FC3;
    }
    .bp {
        color: #366;
    }
    .vc {
        color: #033;
    }
    .vg {
        color: #033;
    }
    .vi {
        color: #033;
    }
    .il {
        color: #F60;
    }

    .css .o,
    .css .o + .nt,
    .css .nt + .nt {
        color: #999;
    }

    .language-bash::before {
        color: #009;
        content: "$ ";
        user-select: none;
    }

    .language-powershell::before {
        color: #009;
        content: "PM> ";
        user-select: none;
    }
}