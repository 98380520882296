/**
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "./variables";

/**
 * Applies the correct css rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 */
@mixin mdc-elevation($z-value) {

    @if type-of($z-value) != number or not unitless($z-value) {
        @error "$z-value must be a unitless number";
    }

    @if $z-value < 0 or $z-value > 24 {
        @error "$z-value must be between 0 and 24";
    }

    #{$mdc-elevation-property}: #{map-get($mdc-elevation-umbra-map, $z-value)} $mdc-elevation-umbra-color #{","}
    #{map-get($mdc-elevation-penumbra-map, $z-value)} $mdc-elevation-penumbra-color #{","}
    #{map-get($mdc-elevation-ambient-map, $z-value)} $mdc-elevation-ambient-color;
}

/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-rule(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
@function mdc-elevation-transition-rule(
    $duration: $mdc-elevation-transition-duration,
    $easing: $mdc-elevation-transition-timing-function) {
    @return #{$mdc-elevation-property} #{$duration} #{$easing};
}

/**
 * Applies the correct css rules needed to have an element transition between elevations.
 * This mixin should be applied to elements whose elevation values will change depending on their
 * context (e.g. when active or disabled).
 */
// NOTE(traviskaufman): Both this mixin and the above function use default parameters so they can
// be used in the same way by clients.
@mixin mdc-elevation-transition(
    $duration: $mdc-elevation-transition-duration,
    $easing: $mdc-elevation-transition-timing-function) {
    transition: mdc-elevation-transition-rule($duration, $easing);
    will-change: $mdc-elevation-property;
}