.btn-group,
.btn-group-vertical {
    @include mdc-elevation(2);

    > .btn {
        @include mdc-elevation(0);

        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 4rem;
        min-height: 4rem;
        line-height: 2.4rem;

        &:active,
        &.active,
        &:focus,
        &.focus {
            @include mdc-elevation(0);
        }

        i,
        [class^="icon-"],
        [class*=" icon-"] {
            vertical-align: middle;
        }
    }

    .btn-group {
        @include mdc-elevation(0);
    }
}

.btn-toolbar {
    align-items: center;
    //background: rgba(0, 0, 0, 0.06);
    min-height: 6.4rem;

    .btn-group {
        @include mdc-elevation(0);

        margin: 0 0.8rem;

        &:last-child {

            &:after {
                display: none;
            }
        }

        .btn {
            @include mdc-elevation(0);

            min-width: 4rem;
            min-height: 4rem;
            line-height: 2.4rem;

            &:not(.dropdown-toggle) {
                padding: 0.8rem;
            }
        }

        .btn-group {
            margin: 0 0.8rem;
        }
    }

    .input-group {
        margin: 0.4rem 0.8rem;
    }
}

//
// Split button dropdowns
//

.btn + .dropdown-toggle-split {
    min-width: 0;
}