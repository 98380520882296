
.navbar {

}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
    display: flex;
    height: 4.8rem;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.navbar-text {
    display: flex;
    height: 4.8rem;
    flex-direction: row;
    align-items: center;
}