#register-v2 {
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .intro {

        .logo {
            width: 12.8rem;
            height: 12.8rem;
            line-height: 12.8rem;
            font-size: 8.6rem;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 2px;
            text-align: center;
        }

        .title {
            font-size: 4.2rem;
            font-weight: 300;
        }

        .description {
            font-size: 1.4rem;
            max-width: 60rem;
        }
    }

    .form-wrapper {
        .form-content {
        width: 41.6rem;
        max-width: 100%;

        .logo {
            width: 12.8rem;
            height: 12.8rem;
            line-height: 12.8rem;
            font-size: 8.6rem;
            font-weight: 500;
            margin: 3.2rem auto;
            color: #FFFFFF;
            border-radius: 2px;
        }

        .title {
        }

        form {
            width: 100%;
            text-align: left;

            .terms-conditions {
                font-size: 1.3rem;
                .form-check {
                    font-size: 1.3rem;
                }
            }

            .submit-button {
            }
        }

        .login {
            font-weight: 500;
            font-size: 1.3rem;

            .text {
            }

            .link {
            }
        }
        }
    }
}

// RESPONSIVE
@include media-breakpoint-down(sm) {

    #register-v2 {

        .form-wrapper {

            form {

                .btn {
                    width: 95%;
                }
            }

            .btn {

                &.google,
                &.facebook {
                    width: 80%;
                }
            }
        }
    }
}