#forgot-password {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-wrapper {
        width: 38.4rem;
        max-width: 38.4rem;
        background: #FFFFFF;
        text-align: center;

        .logo {
            width: 12.8rem;
            height: 12.8rem;
            line-height: 12.8rem;
            font-size: 8.6rem;
            font-weight: 500;
            margin: 3.2rem auto;
            color: #FFFFFF;
            border-radius: 2px;
        }

        .title {
            font-size: 1.7rem;
        }

        form {
            width: 100%;
            text-align: left;

            .submit-button {
                width: 22rem;
            }
        }

        .login {

        }
    }
}

// RESPONSIVE
@include media-breakpoint-down(sm) {

    #forgot-password {

        .form-wrapper {
            padding: 1.6rem;
            padding: 2.4rem;
            width: 100%;

            form {

                .btn {
                    width: 95%;
                }
            }
        }
    }
}